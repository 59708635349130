import { CaptureConsole as CaptureConsoleIntegration, Offline as OfflineIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

export const mainConfig = {
    canvas: {
        dimentions: {
            width: 1200,
            height: 700
        }
    },
    chat: {
        typingTimeout: 10000, // timeout for typing 10 sec
        attachments: {
            maxFileSize: 20000000, // bytes == 20mb
            fileExtantions: ['PNG', 'JPEG', 'JPG', 'TXT', 'DOC', 'DOCX', 'PDF', 'XLS', 'XLSX', 'PPT', 'PPTX']
        }
    },
    media: {
        image: {
            maxFileSize: 20000000, // bytes == 20mb
            fileExtantions: ['PNG', 'JPEG', 'JPG']
        },
        video: {
            maxFileSize: 512000000, // bytes == 500mb
            fileExtantions: ['MP4', 'M4A', 'M4V', 'F4V', 'F4A', 'M4B', 'M4R', 'F4B', 'MOV', '3GP', '3GP2', '3G2', '3GPP', '3GPP2', 'OGG', 'OGA', 'OGV', 'OGX', 'WMV', 'WMA', 'ASF', 'WEBM', 'FLV', 'AVI', 'OP1A']
        },
        audio: {
            maxFileSize: 512000000, // bytes == 500mb
            fileExtantions: ['MP3', 'AAC', 'AC3', 'EAC3', 'OGG', 'WAV', 'WMA', 'FLAC']
        }
    },
    fileTypeByExtention: {
        PNG: 'image',
        JPEG: 'image',
        JPG: 'image',
        TXT: 'text',
        DOC: 'text',
        DOCX: 'text',
        PPT: 'text',
        PPTX: 'text',
        PDF: 'pdf',
        XLS: 'xls',
        XLSX: 'xls',
        MP4: 'video',
        M4A: 'video',
        M4V: 'video',
        F4V: 'video',
        F4A: 'video',
        M4B: 'video',
        M4R: 'video',
        F4B: 'video',
        MOV: 'video',
        '3GP': 'video',
        '3GP2': 'video',
        '3G2': 'video',
        '3GPP': 'video',
        '3GPP2': 'video',
        OGA: 'video',
        OGV: 'video',
        OGX: 'video',
        WMV: 'video',
        ASF: 'video',
        WEBM: 'video',
        FLV: 'video',
        AVI: 'video',
        OP1A: 'video',
        MP3: 'audio',
        AAC: 'audio',
        AC3: 'audio',
        EAC3: 'audio',
        OGG: 'audio',
        WAV: 'audio',
        WMA: 'audio',
        FLAC: 'audio'
    },

    countMessagesForRendering: 150
};

const sanitizeUri = (uri) => uri?.replace(/\/[a-zA-Z0-9_-]{23,24}(?=($|#|\?))/g, '/<hash>');

export const sentryConfig = {
    dsn: 'https://13c66e3652004a8db2bf2afea9fe9cd9@o930653.ingest.sentry.io/5879247',
    integrations: [new Integrations.BrowserTracing({
        //routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),//, [], matchPath),
        beforeNavigate: context => {
            return {
                ...context,
                // Strip the auth token - 23 or 24 characters long
                name: sanitizeUri(window.location.pathname)
            };
        }
    }), new CaptureConsoleIntegration({
        //levels: ["log", "info", "warn", "error", "debug", "assert"]
        levels: ['error', 'debug', 'assert']
    }), new OfflineIntegration()],
    beforeSend: (event) => {
        // Stop propagation of sentry events about sentry events
        if (event.logger === 'console' && event.message?.startsWith('Sentry Logger')) return null;

        event.request.url = sanitizeUri(event.request.url);
        return event;
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Increased depth, so we send more Redux state
    normalizeDepth: 5,
    environment: 'unknown',
    release: __GIT_COMMIT_HASH__
};

export const sentryReduxConfig = {
    actionTransformer: action => {
        if (
            [
                'AV_PROVIDER_AUDIO_STATS_RECEIVED', // Fired several times a second during an AV session
                'CHAT_MESSAGE_BOX_CHANGED', // Fired repeatedly when typing a message, can result in *very* long action sets,
                'AV_PROVIDER_DOMINANT_SPEAKER_CHANGED', // Fired repeatedly during conversation
                'ADD_SUBTITLE_MESSAGE', // Skip some heavily-used subtitle-related actions
                'DISCARD_OUTDATED_SUBTITLE',
                'DISCARD_OUTDATED_SUBTITLE_BATCH'
            ].includes(action.type)
        ) {
            // Clear out overly-frequent actions, just don't send them at all
            return null;
        } else if (
            // Clearing out large actions - keep the name, but remove the payload
            [
                'REFRESH_WHITEBOARDS_LIST',
                'FETCH_SESSION_MESSAGE_SUCCESS_ACTION',
                'SEND_COMMAND_WHITE_BOARD_ACTION',
                'FETCHED_SUCCESS_CURRENT_FOCUSGROUP',
                'EXECUTE_WHITEBOARD_COMMAND',
                'PROCESS_WHITEBOARD_COMMAND',
                'FETCH_SUCCESS_ALL_CONVERSATION_ACTION',
                'VIDEO_CHAT_ALL_CONVERSATIONS_FETCHED',
                'FETCH_SUCCESS_ALL_PARTICIPANTS',
                'FETCH_FILES_SUCCESS_ACTION',
                'GET_LIST_OF_POLLS_SUCCESS',
                'CREATE_SUCCESS_SLIDE_ACTION',
                'VIDEO_CHAT_ACCESS_NUMBERS_FETCHED'
            ].includes(action.type)
        ) {
            // Return null to not log the action to Sentry
            return { type: action.type, debugInformation: 'Contents stripped due to size constraints.' };
        }
        const actionLength = JSON.stringify(action).length;
        if (actionLength > 4098) {
            const error = new Error(`Large action detected: ${action.type}`);
            const scope = new Sentry.Scope();
            scope.setTag('actionType', action.type);
            scope.setContext('action', {
                actionType: action.type,
                length: actionLength
            });
            scope.setLevel('warning');
            Sentry.captureException(error, scope);
        }
        return action;
    }
};
